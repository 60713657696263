import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  innerWidth,
  screen,
  mainWhite,
  lightGrey,
  mainRed,
} from "../components/common/variables";

const Wrapper = styled.div`
  border-top: 22px ${mainWhite} solid;
  background: ${lightGrey};
  min-height: calc(100vh - 265px);
  @media ${screen.xsmall} {
    min-height: 75vh;
    border-top: 15px ${mainWhite} solid;
  }
  @media ${screen.large} {
    border-top: 17px ${mainWhite} solid;
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 41px 22px 50px 22px;
    text-align: center;
    @media ${screen.xsmall} {
      padding: 100px 42px;
    }
    @media ${screen.small} {
      padding: 100px 30px;
    }
    @media ${screen.large} {
      padding: 110px 0;
    }

    .capital-heading {
      span {
        color: ${mainRed};
      }
    }

    .bold-p {
      max-width: 1140px;
      margin: 10px auto 0 auto;
      @media ${screen.xsmall} {
        margin: 20px auto 0 auto;
      }
    }
  }
`;

const DonationSuccessPage = () => {
  return (
    <Layout>
      <SEO title="Successfully Submitted" noIndex />
      <Wrapper>
        <div className="inner-wrapper">
          <h1 className="capital-heading">Thank You For Donation</h1>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default DonationSuccessPage;
